export const apiConfig = {
    apiUrl: {
      acs: "https://acs.devapi.playbook.plus",
      course: "https://course.devapi.playbook.plus",
      orpa: "https://orpa.devapi.playbook.plus",
      aspla: "https://aspla.devapi.playbook.plus",
      skillcard: "https://sc.devapi.playbook.plus",
      docu: "https://docu.devapi.playbook.plus"
    },
    authHeaderKey: "acsp",
    applicationId: "fbe0db28a0954f9d"
};
